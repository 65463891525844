import React from "react";
import { styled } from "@mui/material";
import { Container } from "../components/shared/Container";
import { Hero } from "../components/pages/Home/Hero";
import { About } from "../components/pages/Home/About";
import { Technology } from "../components/pages/Home/Technology";
import { Contact } from "../components/pages/Home/Contact";

const StyledContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  position: "relative",
  background: theme.palette.background.default,
}));

const IndexPage = () => (
  <StyledContainer
    title="Vertex Services"
    description="Software development and consultancy services"
    pathName="/"
  >
    <Hero />
    <About />
    <Technology />
    <Contact />
  </StyledContainer>
);

export default IndexPage;
