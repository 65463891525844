import React from "react";
import { Box, Button } from "@mui/material";
import { Inner } from "../../shared/Inner";
import { Heading } from "../../shared/Heading";

export const Contact: React.FC = () => (
  <Box bgcolor={(theme) => theme.palette.background.paper}>
    <Inner>
      <Heading
        title="Got an idea?"
        subtitle="Get in touch now and we'll respond as soon as we can"
      >
        <Button variant="contained" href="mailto:contact@vertexservices.ee">
          Contact Us
        </Button>
      </Heading>
    </Inner>
  </Box>
);
