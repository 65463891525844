import React from "react";
import { Stack, styled, Typography, Box } from "@mui/material";
import { Inner } from "../../shared/Inner";

import CodeIcon from "@mui/icons-material/Code";
import DevicesIcon from "@mui/icons-material/Devices";
import BusinessIcon from "@mui/icons-material/Business";
import { Heading } from "../../shared/Heading";

const Item = styled(Stack)`
  background: ${({ theme }) => theme.palette.card.light};
  border: 1px solid ${({ theme }) => theme.palette.card.main};
  padding: 1rem;
  height: 100%;
  width: 100%;
  border-radius: 0.75rem;

  ul {
    margin-block-start: 0.5rem;
    margin-block-end: 0.5rem;
  }
`;

export const About: React.FC = () => (
  <Box bgcolor={(theme) => theme.palette.background.paper}>
    <Inner pt={{ xs: "0", md: "4rem" }} pb={{ xs: "4rem", md: "10rem" }}>
      <Heading
        title="Expert solutions"
        subtitle="With over 7 years of experience in the technology startup scene, we
          know that every business has different requirements and unique
          challenges. We offer the following services:"
      />
      <Stack
        spacing="1rem"
        direction={{ xs: "column", md: "row" }}
        height={{ md: "13rem" }}
      >
        <Item>
          <Stack direction="row" spacing="1rem" alignItems="center" mb="0.5rem">
            <CodeIcon sx={{ fontSize: "2.5rem" }} />
            <Typography variant="h6">
                Custom Software Development
            </Typography>
          </Stack>
          <Typography color={(theme) => theme.palette.text.secondary}>
            We convert your idea into solid, safe, and reliable custom software
            with our full-cycle software development services.
          </Typography>
        </Item>
        <Item>
          <Stack direction="row" spacing="1rem" alignItems="center" mb="0.5rem">
            <DevicesIcon sx={{ fontSize: "2.5rem" }} />
            <Typography variant="h6">
                Web Application Services
            </Typography>
          </Stack>
          <Typography color={(theme) => theme.palette.text.secondary}>
            Our team are experts at design, development and shipping of websites and
            custom web and mobile applications, at any stage from proof-of-concept to production launch.
          </Typography>
        </Item>
        <Item>
          <Stack direction="row" spacing="1rem" alignItems="center" mb="0.5rem">
            <BusinessIcon sx={{ fontSize: "2.5rem" }} />
            <Typography variant="h6">
              Startup Consultancy and Business Planning
            </Typography>
          </Stack>
          <Typography color={(theme) => theme.palette.text.secondary}>
            We know what it takes to launch and grow a successful business, and can guide you from ideation all the way through.</Typography>
        </Item>
      </Stack>
    </Inner>
  </Box>
);
